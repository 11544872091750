// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Result from "../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as GraphQL_PPX from "../../../node_modules/@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as GraphqlTag from "graphql-tag";
import * as SpaceX$LiteracyplanetClientFlags from "./SpaceX.bs.js";
import * as QueryHook2$LiteracyplanetClientFlags from "./QueryHook2.bs.js";
import * as FindWordsType$LiteracyplanetClientFlags from "./FindWordsType.bs.js";
import * as LocaleContext$LiteracyplanetClientFlags from "./LocaleContext.bs.js";

var Raw = {};

var query = GraphqlTag.gql([
      "query findWordsQuery($names: [String!]!, $locale: Locale!, $accent: Accent!)  {\ndummy  \n...FindWordsContentFragment   \n}\n",
      ""
    ], FindWordsType$LiteracyplanetClientFlags.FindWordsContentFragment.query);

function parse(value) {
  var value$1 = value["dummy"];
  return {
          dummy: !(value$1 == null) ? value$1.map(function (value) {
                  if (!(value == null)) {
                    return value;
                  }
                  
                }) : undefined,
          findWordsContentFragment: FindWordsType$LiteracyplanetClientFlags.FindWordsContentFragment.verifyArgsAndParse("NonNull_Accent", "NonNull_Locale", "NonNullListOf_NonNull_String", "FindWordsContentFragment", value)
        };
}

function serialize(value) {
  var value$1 = value.dummy;
  return [FindWordsType$LiteracyplanetClientFlags.FindWordsContentFragment.serialize(value.findWordsContentFragment)].reduce(GraphQL_PPX.deepMerge, {
              dummy: value$1 !== undefined ? value$1.map(function (value) {
                      if (value !== undefined) {
                        return value;
                      } else {
                        return null;
                      }
                    }) : null
            });
}

function serializeVariables(inp) {
  var a = inp.names;
  var a$1 = inp.locale;
  var a$2 = inp.accent;
  return {
          names: a.map(function (b) {
                return b;
              }),
          locale: a$1 === "en_BZ" ? "en_BZ" : (
              a$1 === "en_CA" ? "en_CA" : (
                  a$1 === "en_CB" ? "en_CB" : (
                      a$1 === "en_GB" ? "en_GB" : (
                          a$1 === "en_IE" ? "en_IE" : (
                              a$1 === "en_IN" ? "en_IN" : (
                                  a$1 === "en_JM" ? "en_JM" : (
                                      a$1 === "en_NZ" ? "en_NZ" : (
                                          a$1 === "en_PH" ? "en_PH" : (
                                              a$1 === "en_TT" ? "en_TT" : (
                                                  a$1 === "en_US" ? "en_US" : (
                                                      a$1 === "en_ZA" ? "en_ZA" : (
                                                          a$1 === "en_ZW" ? "en_ZW" : "en_AU"
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
          accent: a$2 === "American" ? "American" : (
              a$2 === "British" ? "British" : (
                  a$2 === "Texan" ? "Texan" : "Australian"
                )
            )
        };
}

function makeVariables(names, locale, accent, param) {
  return {
          names: names,
          locale: locale,
          accent: accent
        };
}

var FindWords_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = SpaceX$LiteracyplanetClientFlags.Query.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var FindWords = {
  FindWords_inner: FindWords_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  use: use
};

function handleResponse(response) {
  return /* WordsFound */{
          _0: FindWordsType$LiteracyplanetClientFlags.makeProps(response.findWordsContentFragment)
        };
}

function onLoading(param) {
  return /* LoadingWords */0;
}

var Q = QueryHook2$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

function onResult(query) {
  return Belt_Result.map(query, handleResponse);
}

function useQuery(locale, accent, names) {
  return Curry._4(Q.useWithErrorModal, onLoading, onLoading, onResult, {
              names: names,
              locale: locale,
              accent: accent
            });
}

function useWithContext(names) {
  var api = LocaleContext$LiteracyplanetClientFlags.use(undefined);
  var locale = api.locale;
  var accent = api.accent;
  return useQuery(locale, accent, names);
}

export {
  FindWords ,
  handleResponse ,
  onLoading ,
  Q ,
  onResult ,
  useQuery ,
  useWithContext ,
  
}
/* query Not a pure module */
