// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as GraphQL_PPX from "../../../../node_modules/@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as GraphqlTag from "graphql-tag";
import * as SpaceX$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/SpaceX.bs.js";
import * as QueryHook2$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/QueryHook2.bs.js";
import * as UserContext$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/UserContext.bs.js";
import * as CobraAvatarType$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/CobraAvatarType.bs.js";
import * as StudentFragments$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/StudentFragments.bs.js";
import * as AvatarContext$LiteracyplanetClientLibsActivity from "./AvatarContext.bs.js";
import * as QueryStudentFragments$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/QueryStudentFragments.bs.js";

var Raw = {};

var query = GraphqlTag.gql([
      "query CurrentStudentAvatarQuery($userId: String!)  {\ndummy  \n...CurrentStudentAvatarFragment   \n}\n",
      ""
    ], QueryStudentFragments$LiteracyplanetClientFlags.CurrentStudentAvatarFragment.query);

function parse(value) {
  var value$1 = value["dummy"];
  return {
          dummy: !(value$1 == null) ? value$1.map(function (value) {
                  if (!(value == null)) {
                    return value;
                  }
                  
                }) : undefined,
          currentStudentAvatarFragment: QueryStudentFragments$LiteracyplanetClientFlags.CurrentStudentAvatarFragment.verifyArgsAndParse("NonNull_String", "CurrentStudentAvatarFragment", value)
        };
}

function serialize(value) {
  var value$1 = value.dummy;
  return [QueryStudentFragments$LiteracyplanetClientFlags.CurrentStudentAvatarFragment.serialize(value.currentStudentAvatarFragment)].reduce(GraphQL_PPX.deepMerge, {
              dummy: value$1 !== undefined ? value$1.map(function (value) {
                      if (value !== undefined) {
                        return value;
                      } else {
                        return null;
                      }
                    }) : null
            });
}

function serializeVariables(inp) {
  return {
          userId: inp.userId
        };
}

function makeVariables(userId, param) {
  return {
          userId: userId
        };
}

var CurrentStudentAvatarQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = SpaceX$LiteracyplanetClientFlags.Query.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var CurrentStudentAvatarQuery = {
  CurrentStudentAvatarQuery_inner: CurrentStudentAvatarQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  use: use
};

function handleResponse(res) {
  return /* Avatar */{
          _0: Belt_Option.map(Belt_Option.flatMap(res.currentStudentAvatarFragment.currentStudent, StudentFragments$LiteracyplanetClientFlags.avatar), CobraAvatarType$LiteracyplanetClientFlags.makeAvatar)
        };
}

function onLoading(param) {
  return /* Loading */0;
}

function onResult(query) {
  return Belt_Result.map(query, handleResponse);
}

var Q = QueryHook2$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

function use$1(userId) {
  return Curry._4(Q.useWithErrorModal, onLoading, onLoading, onResult, {
              userId: userId
            });
}

function AvatarQuery$RunQuery(Props) {
  var userId = Props.userId;
  var children = Props.children;
  var avatar = use$1(userId);
  if (avatar) {
    return Curry._1(children, avatar._0);
  } else {
    return null;
  }
}

var RunQuery = {
  make: AvatarQuery$RunQuery
};

function AvatarQuery$Fetch(Props) {
  var userId = Props.userId;
  var game = Props.game;
  var children = Props.children;
  var match = game.activityType;
  if (typeof match === "number" || !(match.TAG === /* CobraActivity */0 && match._0.hasAvatar)) {
    return Curry._1(children, undefined);
  } else {
    return React.createElement(AvatarQuery$RunQuery, {
                userId: userId,
                children: children
              });
  }
}

var Fetch = {
  make: AvatarQuery$Fetch
};

function AvatarQuery(Props) {
  var game = Props.game;
  var children = Props.children;
  var user = UserContext$LiteracyplanetClientFlags.use(undefined);
  var userId = user.userId;
  return React.createElement(AvatarQuery$Fetch, {
              userId: userId,
              game: game,
              children: (function (avatar) {
                  return React.createElement(AvatarContext$LiteracyplanetClientLibsActivity.Provider.make, {
                              value: avatar,
                              children: children
                            });
                })
            });
}

var CurrentStudentAvatarFragment;

var make = AvatarQuery;

export {
  CurrentStudentAvatarFragment ,
  CurrentStudentAvatarQuery ,
  handleResponse ,
  onLoading ,
  onResult ,
  Q ,
  use$1 as use,
  RunQuery ,
  Fetch ,
  make ,
  
}
/* query Not a pure module */
