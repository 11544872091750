// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as GraphqlTag from "graphql-tag";
import * as SpaceX$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/SpaceX.bs.js";
import * as MutationHook2$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/MutationHook2.bs.js";

var Raw = {};

var query = GraphqlTag.gql(["mutation AssignMissionSynchronous($params: AssignMissionSynchronousInput!)  {\nassignMissionSynchronous(params: $params)  {\n__typename  \nstudentActivityId  \nstudentMissionId  \nisAssessment  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.assignMissionSynchronous;
  return {
          assignMissionSynchronous: {
            __typename: value$1.__typename,
            studentActivityId: value$1.studentActivityId,
            studentMissionId: value$1.studentMissionId,
            isAssessment: value$1.isAssessment
          }
        };
}

function serialize(value) {
  var value$1 = value.assignMissionSynchronous;
  var value$2 = value$1.isAssessment;
  var value$3 = value$1.studentMissionId;
  var value$4 = value$1.studentActivityId;
  var value$5 = value$1.__typename;
  var assignMissionSynchronous = {
    __typename: value$5,
    studentActivityId: value$4,
    studentMissionId: value$3,
    isAssessment: value$2
  };
  return {
          assignMissionSynchronous: assignMissionSynchronous
        };
}

function serializeInputObjectAssignMissionSynchronousInput(inp) {
  var a = inp.usersLocale;
  return {
          userId: inp.userId,
          usersLocale: a === "en_BZ" ? "en_BZ" : (
              a === "en_CA" ? "en_CA" : (
                  a === "en_CB" ? "en_CB" : (
                      a === "en_GB" ? "en_GB" : (
                          a === "en_IE" ? "en_IE" : (
                              a === "en_IN" ? "en_IN" : (
                                  a === "en_JM" ? "en_JM" : (
                                      a === "en_NZ" ? "en_NZ" : (
                                          a === "en_PH" ? "en_PH" : (
                                              a === "en_TT" ? "en_TT" : (
                                                  a === "en_US" ? "en_US" : (
                                                      a === "en_ZA" ? "en_ZA" : (
                                                          a === "en_ZW" ? "en_ZW" : "en_AU"
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
          texasActivityRef: inp.texasActivityRef,
          texasMissionRef: inp.texasMissionRef
        };
}

function serializeVariables(inp) {
  return {
          params: serializeInputObjectAssignMissionSynchronousInput(inp.params)
        };
}

function makeVariables(params, param) {
  return {
          params: params
        };
}

function makeInputObjectAssignMissionSynchronousInput(userId, usersLocale, texasActivityRef, texasMissionRef, param) {
  return {
          userId: userId,
          usersLocale: usersLocale,
          texasActivityRef: texasActivityRef,
          texasMissionRef: texasMissionRef
        };
}

var AssignMissionSynchronous_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectAssignMissionSynchronousInput: serializeInputObjectAssignMissionSynchronousInput,
  makeVariables: makeVariables,
  makeInputObjectAssignMissionSynchronousInput: makeInputObjectAssignMissionSynchronousInput
};

var include = SpaceX$LiteracyplanetClientFlags.Mutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var AssignMissionSynchronous = {
  AssignMissionSynchronous_inner: AssignMissionSynchronous_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectAssignMissionSynchronousInput: serializeInputObjectAssignMissionSynchronousInput,
  makeVariables: makeVariables,
  makeInputObjectAssignMissionSynchronousInput: makeInputObjectAssignMissionSynchronousInput,
  use: use
};

var M = MutationHook2$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

function use$1(usersLocale) {
  var match = Curry._1(M.use, undefined);
  var mutate = match[0];
  var assign = function (userId, texasMissionRef, texasActivityRef) {
    return Curry._1(mutate, {
                params: {
                  userId: userId,
                  usersLocale: usersLocale,
                  texasActivityRef: texasActivityRef,
                  texasMissionRef: texasMissionRef
                }
              });
  };
  return [
          assign,
          match[4]
        ];
}

export {
  AssignMissionSynchronous ,
  M ,
  use$1 as use,
  
}
/* query Not a pure module */
