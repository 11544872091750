// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Error$LiteracyplanetClientErrors from "../../../node_modules/@literacyplanet/client-errors/src/Error.bs.js";

function Make(S) {
  var use = function (defaultState, onLoading, onResult, onError, skip, variables) {
    var match = React.useState(function () {
          return Curry._1(defaultState, undefined);
        });
    var setState = match[1];
    var match$1 = Curry._2(S.use, skip, variables);
    var result = match$1[0];
    var handleResponse = function (response) {
      var result = Curry._1(onResult, response);
      if (result.TAG !== /* Ok */0) {
        return Curry._1(onError, result._0);
      }
      var result$1 = result._0;
      return Curry._1(setState, (function (param) {
                    return result$1;
                  }));
    };
    React.useEffect((function () {
            if (skip) {
              Curry._1(setState, (function (param) {
                      return Curry._1(defaultState, undefined);
                    }));
            } else {
              var exit = 0;
              if (result.loading) {
                Curry._1(setState, Curry.__1(onLoading));
              } else if (result.data !== undefined || result.error !== undefined) {
                exit = 1;
              } else {
                handleResponse({
                      TAG: /* Error */1,
                      _0: {
                        TAG: /* GraphqlError */0,
                        _0: "No Data",
                        _1: [],
                        _2: undefined
                      }
                    });
              }
              if (exit === 1) {
                var response = result.data;
                if (response !== undefined) {
                  handleResponse({
                        TAG: /* Ok */0,
                        _0: Caml_option.valFromOption(response)
                      });
                } else {
                  handleResponse({
                        TAG: /* Error */1,
                        _0: {
                          TAG: /* GraphqlError */0,
                          _0: result.error,
                          _1: [],
                          _2: undefined
                        }
                      });
                }
              }
              
            }
            
          }), [result]);
    return [
            match[0],
            match$1[1]
          ];
  };
  var useRefetchWithError = function (defaultState, onLoading, onResult, onError, variables) {
    var match = Error$LiteracyplanetClientErrors.useErrorModal(undefined);
    var match$1 = use(defaultState, onLoading, (function (res) {
            if (res.TAG === /* Ok */0) {
              return Curry._1(onResult, res._0);
            } else {
              return {
                      TAG: /* Error */1,
                      _0: Curry._1(onError, res._0)
                    };
            }
          }), match.onSetError, false, variables);
    return [
            match$1[0],
            match$1[1]
          ];
  };
  var useSkippableWithErrorModalRefetch = function (defaultState, onLoading, onResult, skip, variables) {
    var match = Error$LiteracyplanetClientErrors.useErrorModal(undefined);
    return use(defaultState, onLoading, onResult, match.onSetError, skip, variables);
  };
  var useSkippableWithErrorModal = function (defaultState, onLoading, onResult, skip, variables) {
    return useSkippableWithErrorModalRefetch(defaultState, onLoading, onResult, skip, variables)[0];
  };
  var useWithErrorModal = function (param) {
    return function (param$1) {
      var func = function (param$2, param$3, param$4) {
        return useSkippableWithErrorModal(param, param$1, param$2, param$3, param$4);
      };
      return function (param) {
        return Curry._2(func, param, false);
      };
    };
  };
  return {
          use: use,
          useRefetchWithError: useRefetchWithError,
          useSkippableWithErrorModalRefetch: useSkippableWithErrorModalRefetch,
          useSkippableWithErrorModal: useSkippableWithErrorModal,
          useWithErrorModal: useWithErrorModal
        };
}

export {
  Make ,
  
}
/* react Not a pure module */
