// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Array from "../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as GraphqlTag from "graphql-tag";
import * as SpaceX$LiteracyplanetClientFlags from "./SpaceX.bs.js";
import * as FlagsTypes$LiteracyplanetClientFlags from "./FlagsTypes.bs.js";
import * as QueryHook2$LiteracyplanetClientFlags from "./QueryHook2.bs.js";

var Raw = {};

var query = GraphqlTag.gql(["query Features($featureNames: [String!]!, $context: FeatureContext)  {\ngetFeatures(featureNames: $featureNames, context: $context)  {\n__typename  \nname  \nenabled  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.getFeatures;
  return {
          getFeatures: value$1.map(function (value) {
                return {
                        __typename: value.__typename,
                        name: value.name,
                        enabled: value.enabled
                      };
              })
        };
}

function serialize(value) {
  var value$1 = value.getFeatures;
  var getFeatures = value$1.map(function (value) {
        var value$1 = value.enabled;
        var value$2 = value.name;
        var value$3 = value.__typename;
        return {
                __typename: value$3,
                name: value$2,
                enabled: value$1
              };
      });
  return {
          getFeatures: getFeatures
        };
}

function serializeInputObjectFeatureContext(inp) {
  var a = inp.userId;
  var a$1 = inp.roles;
  var a$2 = inp.idp;
  var a$3 = inp.idpAccountId;
  var a$4 = inp.year;
  var a$5 = inp.product;
  return {
          userId: a !== undefined ? a : undefined,
          roles: a$1 !== undefined ? a$1.map(function (b) {
                  return b;
                }) : undefined,
          idp: a$2 !== undefined ? a$2 : undefined,
          idpAccountId: a$3 !== undefined ? a$3 : undefined,
          year: a$4 !== undefined ? a$4 : undefined,
          product: a$5 !== undefined ? a$5 : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.featureNames;
  var a$1 = inp.context;
  return {
          featureNames: a.map(function (b) {
                return b;
              }),
          context: a$1 !== undefined ? serializeInputObjectFeatureContext(a$1) : undefined
        };
}

function makeVariables(featureNames, context, param) {
  return {
          featureNames: featureNames,
          context: context
        };
}

function makeInputObjectFeatureContext(userId, roles, idp, idpAccountId, year, product, param) {
  return {
          userId: userId,
          roles: roles,
          idp: idp,
          idpAccountId: idpAccountId,
          year: year,
          product: product
        };
}

var GetFeaturesQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectFeatureContext: serializeInputObjectFeatureContext,
  makeVariables: makeVariables,
  makeInputObjectFeatureContext: makeInputObjectFeatureContext
};

var include = SpaceX$LiteracyplanetClientFlags.Query.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var GetFeaturesQuery = {
  GetFeaturesQuery_inner: GetFeaturesQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectFeatureContext: serializeInputObjectFeatureContext,
  makeVariables: makeVariables,
  makeInputObjectFeatureContext: makeInputObjectFeatureContext,
  use: use
};

var GetFeatures = QueryHook2$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

function handleResponse(response) {
  return /* FlagsFound */{
          _0: Belt_Array.reduce(response.getFeatures, {}, (function (acc, f) {
                  acc[f.name] = f.enabled;
                  return acc;
                }))
        };
}

function onResult(query) {
  return Belt_Result.map(query, handleResponse);
}

function use$1(userRoles, userId, idp, idpAccountId, yearLevel, product, unleashFeatures) {
  return Curry._5(GetFeatures.useSkippableWithErrorModal, (function (param) {
                return /* LoadingFlags */0;
              }), (function (param) {
                return /* LoadingFlags */0;
              }), onResult, false, {
              featureNames: Belt_Array.map(unleashFeatures, FlagsTypes$LiteracyplanetClientFlags.unleashFeatureToString),
              context: {
                userId: userId,
                roles: userRoles,
                idp: idp,
                idpAccountId: idpAccountId,
                year: yearLevel,
                product: product
              }
            });
}

export {
  GetFeaturesQuery ,
  GetFeatures ,
  handleResponse ,
  onResult ,
  use$1 as use,
  
}
/* query Not a pure module */
