// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as GraphqlTag from "graphql-tag";
import * as SpaceX$LiteracyplanetClientFlags from "./SpaceX.bs.js";
import * as UserContext$LiteracyplanetClientFlags from "./UserContext.bs.js";
import * as MutationHook2$LiteracyplanetClientFlags from "./MutationHook2.bs.js";

var Raw = {};

var query = GraphqlTag.gql(["mutation markSeenBefore($userId: String!, $seenType: SeenBeforeType!)  {\nmarkSeenBefore(userId: $userId, seenType: $seenType)  \n}\n"]);

function parse(value) {
  return {
          markSeenBefore: value.markSeenBefore
        };
}

function serialize(value) {
  var value$1 = value.markSeenBefore;
  return {
          markSeenBefore: value$1
        };
}

function serializeVariables(inp) {
  var a = inp.seenType;
  return {
          userId: inp.userId,
          seenType: a === "TX_FTUE_PANEL" ? "TX_FTUE_PANEL" : (
              a === "JOURNEY_INTRO" ? "JOURNEY_INTRO" : "STORYVERSE_INTRO"
            )
        };
}

function makeVariables(userId, seenType, param) {
  return {
          userId: userId,
          seenType: seenType
        };
}

var MarkSeenBefore_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = SpaceX$LiteracyplanetClientFlags.Mutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var MarkSeenBefore = {
  MarkSeenBefore_inner: MarkSeenBefore_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  use: use
};

var M = MutationHook2$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

function useSeenBefore(param) {
  var match = Curry._1(M.use, undefined);
  var mutate = match[0];
  return function (userId, seenType) {
    return Curry._1(mutate, {
                userId: userId,
                seenType: seenType
              });
  };
}

function useSeenBeforeWithUser(seenType) {
  var user = UserContext$LiteracyplanetClientFlags.use(undefined);
  var userId = user.userId;
  var markSeenBefore = useSeenBefore(undefined);
  return function (param) {
    Curry._2(markSeenBefore, userId, seenType);
    
  };
}

function useMarkSeen(seenType, markSeen) {
  var markSeenBefore = useSeenBeforeWithUser(seenType);
  React.useEffect((function () {
          if (markSeen) {
            Curry._1(markSeenBefore, undefined);
          }
          
        }), [markSeen]);
  
}

export {
  MarkSeenBefore ,
  M ,
  useSeenBefore ,
  useSeenBeforeWithUser ,
  useMarkSeen ,
  
}
/* query Not a pure module */
