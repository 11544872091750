// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GraphqlTag from "graphql-tag";

var Raw = {};

var query = GraphqlTag.gql(["fragment AvatarSkinFragment on SkinB   {\n__typename  \nid  \ntint  \n}\n"]);

function parse(value) {
  return {
          __typename: value.__typename,
          id: value.id,
          tint: value.tint
        };
}

function serialize(value) {
  var value$1 = value.tint;
  var value$2 = value.id;
  var value$3 = value.__typename;
  return {
          __typename: value$3,
          id: value$2,
          tint: value$1
        };
}

function verifyArgsAndParse(_AvatarSkinFragment, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var AvatarSkinFragment = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Raw$1 = {};

var query$1 = GraphqlTag.gql(["fragment AvatarSlotFragment on SlotB   {\n__typename  \nid  \nname  \n}\n"]);

function parse$1(value) {
  return {
          __typename: value.__typename,
          id: value.id,
          name: value.name
        };
}

function serialize$1(value) {
  var value$1 = value.name;
  var value$2 = value.id;
  var value$3 = value.__typename;
  return {
          __typename: value$3,
          id: value$2,
          name: value$1
        };
}

function verifyArgsAndParse$1(_AvatarSlotFragment, value) {
  return parse$1(value);
}

function verifyName$1(param) {
  
}

var AvatarSlotFragment = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  verifyArgsAndParse: verifyArgsAndParse$1,
  verifyName: verifyName$1
};

var Raw$2 = {};

var query$2 = GraphqlTag.gql([
      "fragment AvatarItemFragment on ItemB   {\n__typename  \nid  \ntextures  \ntint  \nslot  {\n...AvatarSlotFragment   \n}\n\n}\n",
      ""
    ], query$1);

function parse$2(value) {
  var value$1 = value.textures;
  var value$2 = value.tint;
  return {
          __typename: value.__typename,
          id: value.id,
          textures: value$1.map(function (value) {
                return value;
              }),
          tint: value$2.map(function (value) {
                return value;
              }),
          slot: parse$1(value.slot)
        };
}

function serialize$2(value) {
  var value$1 = value.slot;
  var slot = serialize$1(value$1);
  var value$2 = value.tint;
  var tint = value$2.map(function (value) {
        return value;
      });
  var value$3 = value.textures;
  var textures = value$3.map(function (value) {
        return value;
      });
  var value$4 = value.id;
  var value$5 = value.__typename;
  return {
          __typename: value$5,
          id: value$4,
          textures: textures,
          tint: tint,
          slot: slot
        };
}

function verifyArgsAndParse$2(_AvatarItemFragment, value) {
  return parse$2(value);
}

function verifyName$2(param) {
  
}

var AvatarItemFragment = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  verifyArgsAndParse: verifyArgsAndParse$2,
  verifyName: verifyName$2
};

var Raw$3 = {};

var query$3 = GraphqlTag.gql([
      "fragment PlayerEquippedFragment on PlayerEquippedB   {\n__typename  \nid  \nskin  {\n...AvatarSkinFragment   \n}\n\nitems  {\n...AvatarItemFragment   \n}\n\n}\n",
      "",
      ""
    ], query$2, query);

function parse$3(value) {
  var value$1 = value.skin;
  var value$2 = value.items;
  return {
          __typename: value.__typename,
          id: value.id,
          skin: !(value$1 == null) ? parse(value$1) : undefined,
          items: value$2.map(function (value) {
                if (!(value == null)) {
                  return parse$2(value);
                }
                
              })
        };
}

function serialize$3(value) {
  var value$1 = value.items;
  var items = value$1.map(function (value) {
        if (value !== undefined) {
          return serialize$2(value);
        } else {
          return null;
        }
      });
  var value$2 = value.skin;
  var skin = value$2 !== undefined ? serialize(value$2) : null;
  var value$3 = value.id;
  var value$4 = value.__typename;
  return {
          __typename: value$4,
          id: value$3,
          skin: skin,
          items: items
        };
}

function verifyArgsAndParse$3(_PlayerEquippedFragment, value) {
  return parse$3(value);
}

function verifyName$3(param) {
  
}

var PlayerEquippedFragment = {
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  verifyArgsAndParse: verifyArgsAndParse$3,
  verifyName: verifyName$3
};

export {
  AvatarSkinFragment ,
  AvatarSlotFragment ,
  AvatarItemFragment ,
  PlayerEquippedFragment ,
  
}
/* query Not a pure module */
