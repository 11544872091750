// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as GraphqlTag from "graphql-tag";
import * as SpaceX$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/SpaceX.bs.js";
import * as QueryHook2$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/QueryHook2.bs.js";
import * as JourneyCurrentStudent$LiteracyplanetClientSxEntry from "./JourneyCurrentStudent.bs.js";

var Raw = {};

var query = GraphqlTag.gql([
      "query PollJourneyMissionsQuery($userId: String!)  {\ncurrentStudent(userId: $userId)  {\n__typename  \npollForNewMissions: incompleteVisibleMissionsNonOptional  {\n...JourneyStudentMissionFragment   \n}\n\n}\n\n}\n",
      ""
    ], JourneyCurrentStudent$LiteracyplanetClientSxEntry.JourneyStudentMissionFragment.query);

function parse(value) {
  var value$1 = value.currentStudent;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.pollForNewMissions;
    tmp = {
      __typename: value$1.__typename,
      pollForNewMissions: value$2.map(function (value) {
            return JourneyCurrentStudent$LiteracyplanetClientSxEntry.JourneyStudentMissionFragment.verifyArgsAndParse("JourneyStudentMissionFragment", value);
          })
    };
  }
  return {
          currentStudent: tmp
        };
}

function serialize(value) {
  var value$1 = value.currentStudent;
  var currentStudent;
  if (value$1 !== undefined) {
    var value$2 = value$1.pollForNewMissions;
    var pollForNewMissions = value$2.map(function (value) {
          return JourneyCurrentStudent$LiteracyplanetClientSxEntry.JourneyStudentMissionFragment.serialize(value);
        });
    var value$3 = value$1.__typename;
    currentStudent = {
      __typename: value$3,
      pollForNewMissions: pollForNewMissions
    };
  } else {
    currentStudent = null;
  }
  return {
          currentStudent: currentStudent
        };
}

function serializeVariables(inp) {
  return {
          userId: inp.userId
        };
}

function makeVariables(userId, param) {
  return {
          userId: userId
        };
}

var PollMissions_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = SpaceX$LiteracyplanetClientFlags.Query.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var PollMissions = {
  PollMissions_inner: PollMissions_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  use: use
};

var S = QueryHook2$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

function handleResponse(res) {
  return Belt_Option.map(res.currentStudent, (function (s) {
                return s.pollForNewMissions;
              }));
}

function onResult(query) {
  return Belt_Result.map(query, handleResponse);
}

function onLoading(param) {
  
}

function usePoll(poll, timeout, maxAttempts, callback) {
  var match = React.useState(function () {
        return 0;
      });
  var setRetry = match[1];
  var r = match[0];
  React.useEffect((function () {
          if (!poll) {
            return ;
          }
          var id = setTimeout((function (param) {
                  if (r < maxAttempts) {
                    return Curry._1(callback, (function (param) {
                                  return Curry._1(setRetry, (function (i) {
                                                return i + 1 | 0;
                                              }));
                                }));
                  }
                  
                }), timeout);
          return (function (param) {
                    clearTimeout(id);
                    
                  });
        }), [
        r,
        poll
      ]);
  
}

function useSubscription(userId, skip) {
  var match = Curry._5(S.useSkippableWithErrorModalRefetch, onLoading, onLoading, onResult, skip, {
        userId: userId
      });
  var refetch = match[1];
  var res = match[0];
  usePoll(Belt_Option.mapWithDefault(res, false, (function (r) {
              return r.length === 0;
            })), 2000, 10, (function (retry) {
          Curry._1(refetch, undefined).then(function (param) {
                Curry._1(retry, undefined);
                return Promise.resolve(undefined);
              });
          
        }));
  return res;
}

var JourneyStudentMissionFragment;

export {
  JourneyStudentMissionFragment ,
  PollMissions ,
  S ,
  handleResponse ,
  onResult ,
  onLoading ,
  usePoll ,
  useSubscription ,
  
}
/* query Not a pure module */
