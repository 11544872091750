// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import SxGraphqlClientInteropJs from "./SxGraphqlClientInterop.js";
import * as GraphqlClientCtx$LiteracyplanetClientFlags from "./GraphqlClientCtx.bs.js";

var queryGraphql = SxGraphqlClientInteropJs;

function SxGraphqlClient$Provider(Props) {
  var children = Props.children;
  return React.createElement(GraphqlClientCtx$LiteracyplanetClientFlags.Provider.make, {
              value: {
                queryGraphql: queryGraphql
              },
              children: children
            });
}

var Provider = {
  make: SxGraphqlClient$Provider
};

export {
  queryGraphql ,
  Provider ,
  
}
/* queryGraphql Not a pure module */
