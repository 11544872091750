// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Result from "../../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as GraphqlTag from "graphql-tag";
import * as SpaceX$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/SpaceX.bs.js";
import * as QueryHook2$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/QueryHook2.bs.js";
import * as UserContext$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/UserContext.bs.js";

var Raw = {};

var query = GraphqlTag.gql(["query lockingQuery($userId: String!, $pagePath: String!)  {\ncurrentStudent(userId: $userId)  {\n__typename  \nlocked(pagePath: $pagePath)  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.currentStudent;
  return {
          currentStudent: !(value$1 == null) ? ({
                __typename: value$1.__typename,
                locked: value$1.locked
              }) : undefined
        };
}

function serialize(value) {
  var value$1 = value.currentStudent;
  var currentStudent;
  if (value$1 !== undefined) {
    var value$2 = value$1.locked;
    var value$3 = value$1.__typename;
    currentStudent = {
      __typename: value$3,
      locked: value$2
    };
  } else {
    currentStudent = null;
  }
  return {
          currentStudent: currentStudent
        };
}

function serializeVariables(inp) {
  return {
          userId: inp.userId,
          pagePath: inp.pagePath
        };
}

function makeVariables(userId, pagePath, param) {
  return {
          userId: userId,
          pagePath: pagePath
        };
}

var GetLocking_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = SpaceX$LiteracyplanetClientFlags.Query.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var GetLocking = {
  GetLocking_inner: GetLocking_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  use: use
};

var Q = QueryHook2$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

function onLoading(param) {
  return /* LoadingLock */0;
}

function handleResponse(response) {
  var value = response.currentStudent;
  return /* LockFound */{
          _0: value !== undefined ? value.locked : false
        };
}

function onResult(query) {
  return Belt_Result.map(query, handleResponse);
}

function useQuery(userId, pagePath) {
  return Curry._4(Q.useWithErrorModal, onLoading, onLoading, onResult, {
              userId: userId,
              pagePath: pagePath
            });
}

function use$1(pagePath) {
  var user = UserContext$LiteracyplanetClientFlags.use(undefined);
  var userId = user.userId;
  return useQuery(userId, pagePath);
}

export {
  GetLocking ,
  Q ,
  onLoading ,
  handleResponse ,
  onResult ,
  useQuery ,
  use$1 as use,
  
}
/* query Not a pure module */
