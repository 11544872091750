// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as GraphqlTag from "graphql-tag";
import * as SpaceX$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/SpaceX.bs.js";
import * as QueryHook2$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/QueryHook2.bs.js";
import * as UserContext$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/UserContext.bs.js";

var Raw = {};

var query = GraphqlTag.gql(["query StoryverseSpQuery($userId: String!)  {\ncurrentStudent(userId: $userId)  {\n__typename  \ngems  \n}\n\nstoryverse_user_points  {\n__typename  \npoints  \ncurrentLevel  {\n__typename  \npoints  \nlevel  \n}\n\nnextLevel  {\n__typename  \npoints  \nlevel  \n}\n\n}\n\nbog_user_backgrounds  {\n__typename  \nimage  {\n__typename  \ntitle  \n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.currentStudent;
  var value$2 = value.storyverse_user_points;
  var value$3 = value.bog_user_backgrounds;
  return {
          currentStudent: !(value$1 == null) ? ({
                __typename: value$1.__typename,
                gems: value$1.gems
              }) : undefined,
          storyverse_user_points: value$2.map(function (value) {
                var value$1 = value.currentLevel;
                var value$2 = value.nextLevel;
                return {
                        __typename: value.__typename,
                        points: value.points,
                        currentLevel: !(value$1 == null) ? value$1.map(function (value) {
                                return {
                                        __typename: value.__typename,
                                        points: value.points,
                                        level: value.level
                                      };
                              }) : undefined,
                        nextLevel: !(value$2 == null) ? value$2.map(function (value) {
                                return {
                                        __typename: value.__typename,
                                        points: value.points,
                                        level: value.level
                                      };
                              }) : undefined
                      };
              }),
          bog_user_backgrounds: value$3.map(function (value) {
                var value$1 = value.image;
                return {
                        __typename: value.__typename,
                        image: {
                          __typename: value$1.__typename,
                          title: value$1.title
                        }
                      };
              })
        };
}

function serialize(value) {
  var value$1 = value.bog_user_backgrounds;
  var bog_user_backgrounds = value$1.map(function (value) {
        var value$1 = value.image;
        var value$2 = value$1.title;
        var value$3 = value$1.__typename;
        var image = {
          __typename: value$3,
          title: value$2
        };
        var value$4 = value.__typename;
        return {
                __typename: value$4,
                image: image
              };
      });
  var value$2 = value.storyverse_user_points;
  var storyverse_user_points = value$2.map(function (value) {
        var value$1 = value.nextLevel;
        var nextLevel = value$1 !== undefined ? value$1.map(function (value) {
                var value$1 = value.level;
                var value$2 = value.points;
                var value$3 = value.__typename;
                return {
                        __typename: value$3,
                        points: value$2,
                        level: value$1
                      };
              }) : null;
        var value$2 = value.currentLevel;
        var currentLevel = value$2 !== undefined ? value$2.map(function (value) {
                var value$1 = value.level;
                var value$2 = value.points;
                var value$3 = value.__typename;
                return {
                        __typename: value$3,
                        points: value$2,
                        level: value$1
                      };
              }) : null;
        var value$3 = value.points;
        var value$4 = value.__typename;
        return {
                __typename: value$4,
                points: value$3,
                currentLevel: currentLevel,
                nextLevel: nextLevel
              };
      });
  var value$3 = value.currentStudent;
  var currentStudent;
  if (value$3 !== undefined) {
    var value$4 = value$3.gems;
    var value$5 = value$3.__typename;
    currentStudent = {
      __typename: value$5,
      gems: value$4
    };
  } else {
    currentStudent = null;
  }
  return {
          currentStudent: currentStudent,
          storyverse_user_points: storyverse_user_points,
          bog_user_backgrounds: bog_user_backgrounds
        };
}

function serializeVariables(inp) {
  return {
          userId: inp.userId
        };
}

function makeVariables(userId, param) {
  return {
          userId: userId
        };
}

var StoryverseNavigationQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = SpaceX$LiteracyplanetClientFlags.Query.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var StoryverseNavigationQuery = {
  StoryverseNavigationQuery_inner: StoryverseNavigationQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  use: use
};

function convertSpBar(res) {
  return Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(res.storyverse_user_points, 0), (function (p) {
                    return [
                            p.points,
                            Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(p.currentLevel, (function (__x) {
                                            return Belt_Array.get(__x, 0);
                                          })), (function (p) {
                                        return [
                                                p.level,
                                                p.points
                                              ];
                                      })), [
                                  1,
                                  0
                                ]),
                            Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(p.nextLevel, (function (__x) {
                                            return Belt_Array.get(__x, 0);
                                          })), (function (p) {
                                        return [
                                                p.level,
                                                p.points
                                              ];
                                      })), [
                                  2,
                                  p.points
                                ])
                          ];
                  })), {
              fromValue: 0,
              toValue: 200,
              currentValue: 0,
              fromLevel: 1,
              toLevel: 2
            }, (function (param) {
                var match = param[2];
                var match$1 = param[1];
                return {
                        fromValue: match$1[1],
                        toValue: match[1],
                        currentValue: param[0],
                        fromLevel: match$1[0],
                        toLevel: match[0]
                      };
              }));
}

function convertLocation(res) {
  return Belt_Array.reduce(res.bog_user_backgrounds, "Shabby Hut", (function (acc, b) {
                return b.image.title;
              }));
}

function convertGems(res) {
  return Belt_Option.mapWithDefault(res.currentStudent, "0", (function (u) {
                return String(u.gems);
              }));
}

function convert(res) {
  return {
          location: convertLocation(res),
          spBar: convertSpBar(res),
          gems: convertGems(res)
        };
}

function handleResponse(res) {
  return /* NavLoaded */{
          _0: convert(res)
        };
}

function onResult(query) {
  return Belt_Result.map(query, handleResponse);
}

var Q = QueryHook2$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

function onLoading(param) {
  return /* LoadingNav */0;
}

function defaultState(param) {
  return /* LoadingNav */0;
}

function use$1(skip) {
  var u = UserContext$LiteracyplanetClientFlags.use(undefined);
  var userId = u.userId;
  return Curry._5(Q.useSkippableWithErrorModal, defaultState, onLoading, onResult, skip, {
              userId: userId
            });
}

var decode = Json_decode.string;

var error = "Error fetching StoryverseNavigationQuery";

export {
  StoryverseNavigationQuery ,
  decode ,
  convertSpBar ,
  convertLocation ,
  convertGems ,
  convert ,
  handleResponse ,
  onResult ,
  Q ,
  onLoading ,
  defaultState ,
  error ,
  use$1 as use,
  
}
/* query Not a pure module */
