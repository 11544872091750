import * as Core from '@literacyplanet/client_core';
import {print} from 'graphql/language/printer';

// const signIn = () => {
//   // iOS
//   if (window.webkit && window.webkit.messageHandlers) {
//     window.webkit.messageHandlers.signInMessageHandler.postMessage({
//       param1: 'Signing in',
//       param2: '1000'
//     });
//   }

//   // Android
//   if (typeof bridge !== 'undefined') {
//     bridge.signInMessageHandler('Signing in');
//   }
// };

// if (payload.errors) {
//   // Stuart and I tested this locally using the browser, and we couldn't replicate this issue in isolation.
//   // The redux-oidc OidcProvider automatically performs a silent token renewal before the token expires.
//   // To test this functionality, I commented out the onAccessTokenExpiring and onAccessTokenExpired functions
//   // on the provider. I then set the token expiry to 1 minute, waited for the token to expire, and attempted to access
//   // a query that required auth.

//   const unauthorised = payload.errors.find(({ message }) => message.includes('Unauthorised'));
//   if (unauthorised) {
//     // if mobile or message includes a reason sign in again
//     if (process.env.MOBILE) {
//       openIdUserManager.removeUser().then(() =>
//         signIn()
//       );
//     } else {
//       // where does it go if not exired?
//       // all I see is
//       payload.errors.find(({ message }) => message.includes('Unauthorised') && message.includes('expired'))
//         ? openIdSilentRenew(store, openIdUserManager)
//         : openIdUserManager.removeUser();
//     }
//   }
// }

let oidc = {
  incr: 0,
  state: 'IDLE',
  user: null,
  check: async () => {
    oidc.incr++;

    if (oidc.state == 'IDLE') {
      oidc.state = 'CHECKING';
      let prevUser = oidc.user;

      // console.log('CHECKING...')
      oidc.user = new Promise(async (resolve, reject) => {
        let user = prevUser
          ? await prevUser
          : await Core.openIdUserManager.getUser();

        // console.log('user.expires_in', user.expires_in)
        if (user.expires_in < 5) {
          // console.log('RENEWING...')
          try {
            user = await Core.openIdUserManager.signinSilent();
          } catch(e) {
            if (e.error === 'login_required') {
              await Core.openIdUserManager.removeUser();
            } else {
              reject(e);
            }
          } 
        }

        // console.log('IDLE')
        resolve(user);

        oidc.state = 'IDLE';
      });
    }

    // console.log('BLOCKING...')
    let user = await oidc.user;
    // console.log('user', user.expires_in, user.access_token);

    return {
      'authorization': user.access_token,
      'X-IdToken': user.id_token
    };
  }
};

export default async function (ast, variables, cb) {
  const url = process.env.GRAPHQL_SERVER + process.env.GRAPHQL_PATH;
  // To test 502's
  // const url = 'https://httpstat.us/504?sleep=5000'

  const headers = await oidc.check();

  const query = print(ast);
  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      body: JSON.stringify({
        query,
        variables
      })
    });

    if (!res.ok) {
      return cb({
        error: 'HTTP status ' + res.status
      });
    }

    const payload = await res.json();

    return cb({
      ...payload,
      error: payload.errors && payload.errors
        .map(e => e.message)
        .join(', ')
    });

  } catch (e) {
    // Failed to fetch
    // https://sentry.io/answers/failed-to-fetch-javascript/

    // ... or somthing else exploded inside the try block
    return cb({
      error: e.message
    });
  }
};
