// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as GraphqlTag from "graphql-tag";
import * as SpaceX$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/SpaceX.bs.js";
import * as QueryHook2$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/QueryHook2.bs.js";
import * as UserContext$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/UserContext.bs.js";

var Raw = {};

var query = GraphqlTag.gql(["query CobraFont($userId: String!)  {\ncurrentStudent(userId: $userId)  {\n__typename  \nenrollee  {\n__typename  \naccount  {\n__typename\nfont  \n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.currentStudent;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.enrollee;
    var tmp$1;
    if (value$2 == null) {
      tmp$1 = undefined;
    } else {
      var value$3 = value$2.account;
      var value$4 = value$3.font;
      var tmp$2;
      switch (value$4) {
        case "AUS_NSW_ACT" :
            tmp$2 = "AUS_NSW_ACT";
            break;
        case "AUS_QLD" :
            tmp$2 = "AUS_QLD";
            break;
        case "AUS_SA" :
            tmp$2 = "AUS_SA";
            break;
        case "AUS_TAS" :
            tmp$2 = "AUS_TAS";
            break;
        case "AUS_VIC_NT_WA" :
            tmp$2 = "AUS_VIC_NT_WA";
            break;
        case "KGNEATLY" :
            tmp$2 = "KGNEATLY";
            break;
        case "NZ_DEFAULT" :
            tmp$2 = "NZ_DEFAULT";
            break;
        case "SASSOON_SANS_US_REG" :
            tmp$2 = "SASSOON_SANS_US_REG";
            break;
        default:
          throw {
                RE_EXN_ID: "Not_found",
                Error: new Error()
              };
      }
      tmp$1 = {
        __typename: value$2.__typename,
        account: {
          font: tmp$2
        }
      };
    }
    tmp = {
      __typename: value$1.__typename,
      enrollee: tmp$1
    };
  }
  return {
          currentStudent: tmp
        };
}

function serialize(value) {
  var value$1 = value.currentStudent;
  var currentStudent;
  if (value$1 !== undefined) {
    var value$2 = value$1.enrollee;
    var enrollee;
    if (value$2 !== undefined) {
      var value$3 = value$2.account;
      var value$4 = value$3.font;
      var font = value$4 === "KGNEATLY" ? "KGNEATLY" : (
          value$4 === "AUS_NSW_ACT" ? "AUS_NSW_ACT" : (
              value$4 === "SASSOON_SANS_US_REG" ? "SASSOON_SANS_US_REG" : (
                  value$4 === "AUS_QLD" ? "AUS_QLD" : (
                      value$4 === "AUS_TAS" ? "AUS_TAS" : (
                          value$4 === "NZ_DEFAULT" ? "NZ_DEFAULT" : (
                              value$4 === "AUS_SA" ? "AUS_SA" : "AUS_VIC_NT_WA"
                            )
                        )
                    )
                )
            )
        );
      var account = {
        font: font
      };
      var value$5 = value$2.__typename;
      enrollee = {
        __typename: value$5,
        account: account
      };
    } else {
      enrollee = null;
    }
    var value$6 = value$1.__typename;
    currentStudent = {
      __typename: value$6,
      enrollee: enrollee
    };
  } else {
    currentStudent = null;
  }
  return {
          currentStudent: currentStudent
        };
}

function serializeVariables(inp) {
  return {
          userId: inp.userId
        };
}

function makeVariables(userId, param) {
  return {
          userId: userId
        };
}

var CobraFontQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = SpaceX$LiteracyplanetClientFlags.Query.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var CobraFontQuery = {
  CobraFontQuery_inner: CobraFontQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  use: use
};

var Q = QueryHook2$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

function handleResponse(r) {
  return Belt_Option.flatMap(r.currentStudent, (function (s) {
                return Belt_Option.map(Belt_Option.map(s.enrollee, (function (e) {
                                  return e.account.font;
                                })), (function (font) {
                              return font;
                            }));
              }));
}

function onResult(query) {
  return Belt_Result.map(query, handleResponse);
}

function onLoading(param) {
  
}

function use$1(param) {
  var user = UserContext$LiteracyplanetClientFlags.use(undefined);
  var userId = user.userId;
  return Curry._4(Q.useWithErrorModal, onLoading, onLoading, onResult, {
              userId: userId
            });
}

function CobraFontQuery$1(Props) {
  var children = Props.children;
  return Curry._1(children, use$1(undefined));
}

var make = CobraFontQuery$1;

export {
  CobraFontQuery ,
  Q ,
  handleResponse ,
  onResult ,
  onLoading ,
  use$1 as use,
  make ,
  
}
/* query Not a pure module */
