// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as GraphqlTag from "graphql-tag";
import * as SpaceX$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/SpaceX.bs.js";
import * as QueryHook2$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/QueryHook2.bs.js";

var Raw = {};

var query = GraphqlTag.gql(["query DeepLinkMissionQuery($userId: String!, $refId: ID!)  {\ncurrentStudent(userId: $userId)  {\n__typename  \nfindDeepLinkByTexasMissionRef(id: $refId)  {\n__typename\n...on DeepLinkStudentMission   {\n__typename  \nid  \n}\n\n...on DeepLinkMission   {\n__typename  \nrefId  \ntitle  \nusaTitle  \nactivities  {\n__typename  \nkind  \nrefId  \ntitle  \nbestScore  \n}\n\n}\n\n...on DeepLinkNotFound   {\n__typename  \nmessage  \n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.currentStudent;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.findDeepLinkByTexasMissionRef;
    var typename = value$2["__typename"];
    var tmp$1;
    switch (typename) {
      case "DeepLinkMission" :
          var value$3 = value$2.usaTitle;
          var value$4 = value$2.activities;
          tmp$1 = {
            NAME: "DeepLinkMission",
            VAL: {
              __typename: value$2.__typename,
              refId: value$2.refId,
              title: value$2.title,
              usaTitle: !(value$3 == null) ? value$3 : undefined,
              activities: value$4.map(function (value) {
                    var value$1 = value.kind;
                    var value$2 = value.bestScore;
                    return {
                            __typename: value.__typename,
                            kind: !(value$1 == null) ? value$1 : undefined,
                            refId: value.refId,
                            title: value.title,
                            bestScore: !(value$2 == null) ? value$2 : undefined
                          };
                  })
            }
          };
          break;
      case "DeepLinkNotFound" :
          tmp$1 = {
            NAME: "DeepLinkNotFound",
            VAL: {
              __typename: value$2.__typename,
              message: value$2.message
            }
          };
          break;
      case "DeepLinkStudentMission" :
          tmp$1 = {
            NAME: "DeepLinkStudentMission",
            VAL: {
              __typename: value$2.__typename,
              id: value$2.id
            }
          };
          break;
      default:
        tmp$1 = {
          NAME: "FutureAddedValue",
          VAL: value$2
        };
    }
    tmp = {
      __typename: value$1.__typename,
      findDeepLinkByTexasMissionRef: tmp$1
    };
  }
  return {
          currentStudent: tmp
        };
}

function serialize(value) {
  var value$1 = value.currentStudent;
  var currentStudent;
  if (value$1 !== undefined) {
    var value$2 = value$1.findDeepLinkByTexasMissionRef;
    var variant = value$2.NAME;
    var findDeepLinkByTexasMissionRef;
    if (variant === "DeepLinkStudentMission") {
      var value$3 = value$2.VAL.id;
      findDeepLinkByTexasMissionRef = {
        __typename: "DeepLinkStudentMission",
        id: value$3
      };
    } else if (variant === "FutureAddedValue") {
      findDeepLinkByTexasMissionRef = value$2.VAL;
    } else if (variant === "DeepLinkNotFound") {
      var value$4 = value$2.VAL.message;
      findDeepLinkByTexasMissionRef = {
        __typename: "DeepLinkNotFound",
        message: value$4
      };
    } else {
      var value$5 = value$2.VAL;
      var value$6 = value$5.activities;
      var activities = value$6.map(function (value) {
            var value$1 = value.bestScore;
            var bestScore = value$1 !== undefined ? value$1 : null;
            var value$2 = value.title;
            var value$3 = value.refId;
            var value$4 = value.kind;
            var kind = value$4 !== undefined ? value$4 : null;
            var value$5 = value.__typename;
            return {
                    __typename: value$5,
                    kind: kind,
                    refId: value$3,
                    title: value$2,
                    bestScore: bestScore
                  };
          });
      var value$7 = value$5.usaTitle;
      var usaTitle = value$7 !== undefined ? value$7 : null;
      var value$8 = value$5.title;
      var value$9 = value$5.refId;
      findDeepLinkByTexasMissionRef = {
        __typename: "DeepLinkMission",
        refId: value$9,
        title: value$8,
        usaTitle: usaTitle,
        activities: activities
      };
    }
    var value$10 = value$1.__typename;
    currentStudent = {
      __typename: value$10,
      findDeepLinkByTexasMissionRef: findDeepLinkByTexasMissionRef
    };
  } else {
    currentStudent = null;
  }
  return {
          currentStudent: currentStudent
        };
}

function serializeVariables(inp) {
  return {
          userId: inp.userId,
          refId: inp.refId
        };
}

function makeVariables(userId, refId, param) {
  return {
          userId: userId,
          refId: refId
        };
}

var DeepLinkMissionQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = SpaceX$LiteracyplanetClientFlags.Query.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var DeepLinkMissionQuery = {
  DeepLinkMissionQuery_inner: DeepLinkMissionQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  use: use
};

var Q = QueryHook2$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

function makeActivityProps(a) {
  return {
          id: a.refId,
          title: a.title,
          kind: Belt_Option.getWithDefault(a.kind, ""),
          complete: false,
          bestScore: a.bestScore,
          inProgress: false,
          locked: false,
          assessment: false
        };
}

function missionTitleForLocale(mission, localeString) {
  if (localeString === "en_US") {
    return Belt_Option.getWithDefault(mission.usaTitle, mission.title);
  } else {
    return mission.title;
  }
}

function makeActivityScores(deeplink, localeString) {
  return {
          TAG: /* ActivityScores */1,
          _0: {
            currentStudent: {},
            activities: Belt_Array.map(deeplink.activities, makeActivityProps),
            mission: {
              title: missionTitleForLocale(deeplink, localeString),
              chest: "GEMS"
            }
          }
        };
}

function onLoading(param) {
  return /* LoadingMission */0;
}

function onResult(localeString, query) {
  return Belt_Result.flatMap(query, (function (response) {
                return Belt_Option.mapWithDefault(response.currentStudent, {
                            TAG: /* Error */1,
                            _0: {
                              TAG: /* ErrorWithMessage */4,
                              _0: "MissionLobbyQuery",
                              _1: "error getting mission details"
                            }
                          }, (function (student) {
                              var match = student.findDeepLinkByTexasMissionRef;
                              var tmp;
                              if (typeof match === "object") {
                                var variant = match.NAME;
                                if (variant === "DeepLinkMission") {
                                  tmp = makeActivityScores(match.VAL, localeString);
                                } else if (variant === "DeepLinkStudentMission") {
                                  tmp = {
                                    TAG: /* CurrentStudentMission */0,
                                    _0: match.VAL.id
                                  };
                                } else if (variant === "DeepLinkNotFound") {
                                  tmp = /* NotFound */1;
                                } else {
                                  throw {
                                        RE_EXN_ID: "Match_failure",
                                        _1: [
                                          "DeepLinkMissionQuery.res",
                                          101,
                                          8
                                        ],
                                        Error: new Error()
                                      };
                                }
                              } else {
                                throw {
                                      RE_EXN_ID: "Match_failure",
                                      _1: [
                                        "DeepLinkMissionQuery.res",
                                        101,
                                        8
                                      ],
                                      Error: new Error()
                                    };
                              }
                              return {
                                      TAG: /* Ok */0,
                                      _0: tmp
                                    };
                            }));
              }));
}

function use$1(userId, missionRef, localeString) {
  return Curry._4(Q.useWithErrorModal, onLoading, onLoading, (function (param) {
                return onResult("en_US", param);
              }), {
              userId: userId,
              refId: missionRef
            });
}

export {
  DeepLinkMissionQuery ,
  Q ,
  makeActivityProps ,
  missionTitleForLocale ,
  makeActivityScores ,
  onLoading ,
  onResult ,
  use$1 as use,
  
}
/* query Not a pure module */
