// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ContextMaker$LiteracyplanetClientFlags from "./ContextMaker.bs.js";

var Api = {
  name: "GraphqlClientCtx"
};

var include = ContextMaker$LiteracyplanetClientFlags.Make(Api);

var initialContext = include.initialContext;

var context = include.context;

var P = include.P;

var Provider = include.Provider;

var ContextMakerProviderNotFound = include.ContextMakerProviderNotFound;

var use = include.use;

export {
  Api ,
  initialContext ,
  context ,
  P ,
  Provider ,
  ContextMakerProviderNotFound ,
  use ,
  
}
/* include Not a pure module */
